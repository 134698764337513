/*theme immotep*/
#immotep-header-img {
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: center top;
    background-image: url(/public/assets/img/57004/immotep_header.jpeg);
    background-size: cover;
    padding-top: 500px;
}

#immotep p.sub-heading {
    font-size: 1.2rem;
}

#immotep .sld-txt-content {
    font-size: 2.375rem;
    font-weight: 700;
}
#immotep .container-wrap {
    padding-top: 5% !important;
}

#immotep .wrap-icon:hover {

    background: #cb213a;
    transition: all .3s ease-in-out;

}

#immotep .wrap-icon:hover p,#immotep .wrap-icon:hover h2 {

    color:white;
}

#immotep .wrap-icon:hover svg g {

    fill:white;
}

.immotep-menu .navbar-nav .nav-item:hover::before {
    background-image: -moz-linear-gradient(0deg, #132e54 0%, #132e54 100%);
    background-image: -webkit-linear-gradient(0deg, #132e54 0%, #132e54 100%);
    background-image: -ms-linear-gradient(0deg, #132e54 0%, #132e54 100%);
}

.immotep57.home-lst-srv .item g {
    fill: #132e54;
}

.immotep-menu .navbar-nav > .active > a {
    color: #cb213a !important;
}

.immotep57-lst h2 {
    margin-top: 18px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-weight: bolder;
}